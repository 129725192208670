import React, {useState} from 'react';
import Column from '@amzn/meridian/column';
import {css} from 'emotion';
import {useHistory} from 'react-router-dom';
import {useAppSelector} from '../../app/hooks';
import {selectCachedBusiness, selectCachedCountry, selectCachedRegion} from '../cached-state/cachedStateSlice';

import SiteListTable from './components/SiteListTable';
import {GetSiteListResponse} from '../../common/apis/models/getSiteListResponse';
import {IPageProps} from '../../common/pages/models';
import BucklePage from '../../common/pages/BucklePage';

const mastheadAndColumnSpacing = css`
  padding-top: 18px;
  padding-left: 1.5%;
  padding-right: 1.5%;
`;

const SitePage = () => {
  const history = useHistory();

  const [siteList, setSiteList] = useState<GetSiteListResponse | void>();
  const businessSelection = useAppSelector(selectCachedBusiness);
  const regionSelection = useAppSelector(selectCachedRegion);
  const countrySelection = useAppSelector(selectCachedCountry);

  return (
    <Column className={mastheadAndColumnSpacing}>
      <SiteListTable
        history={history}
        siteList={siteList}
        setSiteList={setSiteList}
        businessType={businessSelection!}
        region={regionSelection!}
        country={countrySelection!}
      />
    </Column>
  );
};

export default (props: IPageProps) => (
  <BucklePage title="Sites" {...props}>
    <SitePage />
  </BucklePage>
);
