import React, {useCallback, useEffect, useState} from 'react';
import {RouteComponentProps} from 'react-router-dom';
import {ITableProps, kaReducer, Table} from 'ka-table';
import {loadData, updateData} from 'ka-table/actionCreators';
import {DataType, SortingMode, PagingPosition, SortDirection, ActionType} from 'ka-table/enums';
import {ICellTextProps} from 'ka-table/props';
import {DispatchFunc} from 'ka-table/types';
import {css} from 'emotion';
import 'ka-table/style.scss';
import {search} from 'ka-table/actionCreators';
import {Column} from 'ka-table/models';
import Row from '@amzn/meridian/row';
import {GetConstraintResponse} from '../../../common/apis/models/getConstraintResponse';
import {getBaseTableStyle} from '../../../common/components/ApiDataTable/ApiDataTableStyles';
import DebouncedSearch from '../../../common/components/DebouncedSearch';
import {useAppSelector} from '../../../app/hooks';
import {ITheme} from '../../../common/styles/themes/models';
import {selectTheme} from '../../themes/themeStateSlice';
import DownloadTemplateButton from './DownloadTemplateButton';
import UploadTemplateButton from './UploadTemplateButton';
import {SiteDetailsCell} from '../../site-page/components/SiteListCells';

const columnWidth = 80;

const columns: Column[] = [
  {key: 'siteId', title: 'Site ID', dataType: DataType.String, style: {width: columnWidth}},
  {
    key: 'siteName',
    title: 'Site Name',
    dataType: DataType.String,
    style: {width: 100},
    sortDirection: SortDirection.Ascend,
  },
];

let currDate = new Date();
const currentWeekSunday = new Date(currDate.setDate(currDate.getDate() - currDate.getDay())).toLocaleDateString();
export const weeksToDisplay = 18; // TODO configure
const weekLength = 7; // TODO Display based on Metric granularity. https://sim.amazon.com/issues/GLADE-333
for (let i = 0; i < weeksToDisplay; i++) {
  const date = new Date(currentWeekSunday.valueOf());
  date.setDate(date.getDate() + i * weekLength);
  columns.push({
    key: `column${i}`,
    title: `${date.toLocaleString('default', {month: 'short'})}-${date.toLocaleString('default', {day: '2-digit'})}`,
    dataType: DataType.String,
    style: {width: columnWidth},
  });
}

const getColumnTypeMapping = (
  history: RouteComponentProps['history'],
  theme: ITheme,
  props: React.PropsWithChildren<ICellTextProps>
) => {
  switch (props.column.key) {
    case 'siteId':
      return <SiteDetailsCell history={history} theme={theme} {...props} />;
  }
};

const tablePropsInit: ITableProps = {
  columns,
  rowKeyField: 'id',
  singleAction: loadData(),
  sortingMode: SortingMode.Single,
  paging: {
    enabled: true,
    pageIndex: 0,
    pageSize: 25,
    pageSizes: [25, 50, 100],
    position: PagingPosition.Bottom,
  },
  search: ({searchText, rowData}) => {
    return (
      rowData['siteId'].toLowerCase().includes(searchText.toLowerCase()) ||
      rowData['siteName'].toLowerCase().includes(searchText.toLowerCase())
    );
  },
  sort: ({column}) => {
    if (['siteId', 'siteName'].includes(column.key)) {
      return (a, b) => {
        const sortAsc = column.sortDirection === SortDirection.Ascend ? -1 : 1;
        const sortDesc = (sortAsc * -1) as 1 | -1;
        return a === b ? 0 : a < b ? sortAsc : sortDesc;
      };
    }
    return (a, b) => {
      a = parseFloat(a.replace(/,/g, ''));
      b = parseFloat(b.replace(/,/g, ''));
      const sortAsc = column.sortDirection === SortDirection.Ascend ? -1 : 1;
      const sortDesc = (sortAsc * -1) as 1 | -1;
      return a === b ? 0 : a < b ? sortAsc : sortDesc;
    };
  },
};

const rowSpacing = css`
  padding-bottom: 6px;
`;

const buttonsRight = css`
  margin-left: auto !important;
  margin-right: 1.5%;
  overflow: hidden;
`;

interface IProps {
  history: RouteComponentProps['history'];
  constraintDetails: GetConstraintResponse | void;
  constraintId: string;
  businessType: string;
  region: string;
  user: string;
  enableEdits: boolean;
  country?: string;
}

const stickyColumnOffset = 39;

const ConstraintDetailsTable = ({
  history,
  constraintDetails,
  constraintId,
  businessType,
  region,
  user,
  enableEdits,
  country,
}: IProps) => {
  const theme = useAppSelector(selectTheme);

  const [tableProps, changeTableProps] = useState(tablePropsInit);
  const dispatch: DispatchFunc = useCallback(
    (action) => {
      changeTableProps((prevState: ITableProps) => kaReducer(prevState, action));

      if (action.type === ActionType.LoadData) {
        dispatch(updateData(constraintDetails!.valuesData));
      }
    },
    [constraintDetails]
  );

  // Perform client-side search
  const [searchInput, setSearchInput] = useState('');
  useEffect(() => {
    dispatch(search(searchInput));
  }, [dispatch, searchInput]);

  const getTableStyle = (theme: ITheme) => {
    return css`
      ${getBaseTableStyle(theme)}

      .ka-cell {
        text-align: right;
      }
    `;
  };

  const tableStyle = getTableStyle(theme);
  return (
    <div className={tableStyle}>
      <Row className={rowSpacing}>
        <DebouncedSearch placeholder="Search for sites..." searchInput={searchInput} setSearchInput={setSearchInput} />
        <Row className={buttonsRight}>
          <DownloadTemplateButton
            constraintId={constraintId}
            businessType={businessType}
            region={region}
            user={user}
            shouldEnable={enableEdits}
            country={country}
          />
          <UploadTemplateButton
            constraintId={constraintId}
            businessType={businessType}
            region={region}
            user={user}
            shouldEnable={enableEdits}
            country={country}
          />
        </Row>
      </Row>

      <Table
        {...tableProps}
        dispatch={dispatch}
        childComponents={{
          noDataRow: {
            content: () => 'No Data Found',
          },
          cellText: {
            content: getColumnTypeMapping.bind(getColumnTypeMapping, history, theme),
          },
          headCell: {
            elementAttributes: (props) => {
              if (props.column.key === 'siteId') {
                return {
                  style: {
                    ...props.column.style,
                    position: 'sticky',
                    left: 0,
                    zIndex: 10,
                    textAlign: 'left',
                  },
                };
              } else if (props.column.key === 'siteName') {
                return {
                  style: {
                    ...props.column.style,
                    position: 'sticky',
                    left: columnWidth + stickyColumnOffset,
                    zIndex: 10,
                    textAlign: 'left',
                  },
                };
              }
            },
          },
          cell: {
            elementAttributes: (props) => {
              if (props.column.key === 'siteId') {
                return {
                  style: {
                    ...props.column.style,
                    position: 'sticky',
                    left: 0,
                    color: theme.StickyColumn,
                    backgroundColor: theme.StickyColumnBackground,
                    textAlign: 'left',
                    paddingRight: 0,
                  },
                };
              } else if (props.column.key === 'siteName') {
                return {
                  style: {
                    ...props.column.style,
                    position: 'sticky',
                    left: columnWidth + stickyColumnOffset,
                    color: theme.StickyColumn,
                    backgroundColor: theme.StickyColumnBackground,
                    textAlign: 'left',
                    paddingRight: 0,
                    overflow: 'hidden',
                    textOverflow: 'ellipsis',
                  },
                };
              }
            },
          },
        }}
      />
    </div>
  );
};

export default ConstraintDetailsTable;
