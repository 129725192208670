import React, {useEffect, useState} from 'react';
import AppLayout from '@amzn/meridian/app-layout';
import Masthead from '@amzn/meridian/masthead';
import {BrowserRouter, Redirect, Route, Switch} from 'react-router-dom';

import CachedLandingPage from '../features/cached-landing-page/CachedLandingPage';
import HistoryPage from '../features/history-page/HistoryPage';
import NavBar from '../features/navigation-bar/NavBar';
import SelectBusinessPage from '../features/select-business-page/SelectBusinessPage';
import {useAppSelector} from './hooks';
import {selectCachedUserSelection} from '../features/cached-state/cachedStateSlice';
import ConstraintDetailsPage from '../features/constraint-details-page/ConstraintDetailsPage';
import ErrorPage from '../features/error-page/ErrorPage';
import SiteDetailsPage from '../features/site-details-page/SiteDetailsPage';
import SitePage from '../features/site-page/SitePage';
import UnauthorizedPage from '../features/unauthorized-page/UnauthorizedPage';
import ConstraintsPage from '../features/constraints-page/ConstraintsPage';
import {Metadata} from '../common/components/MetadataTable';

const BuckleApp = () => {
  const userSelection = useAppSelector(selectCachedUserSelection);
  const [pageTitle, setPageTitle] = useState<string | void>();
  const [entityMetadata, setEntityMetadata] = useState<Metadata[] | void>();
  const isAuthorized = Boolean(userSelection);
  const RedirectToSelection = <Redirect to="/select-business" />;
  const redirect = (page: JSX.Element) => (isAuthorized ? page : RedirectToSelection);

  useEffect(() => {
    document.title = pageTitle ? `Buckle - ${pageTitle}` : 'Buckle';
  }, [pageTitle]);

  const pageProps = {setPageTitle, setEntityMetadata};

  return (
    <BrowserRouter>
      <AppLayout headerComponent={Masthead} backgroundColor="alternativeSecondary">
        <NavBar pageTitle={pageTitle} metadataList={entityMetadata} />

        <Switch>
          <Route path="/unauthorized" exact={true}>
            <UnauthorizedPage />
          </Route>
          <Route path="/select-business" exact={true}>
            <SelectBusinessPage />
          </Route>
          <Route path="/" exact={true}>
            {redirect(<CachedLandingPage {...pageProps} />)}
          </Route>
          <Route path="/constraints" exact={true}>
            {redirect(<ConstraintsPage {...pageProps} />)}
          </Route>
          <Route path="/constraints/:constraint" exact={true}>
            {redirect(<ConstraintDetailsPage {...pageProps} />)}
          </Route>
          <Route path="/sites" exact={true}>
            {redirect(<SitePage {...pageProps} />)}
          </Route>
          <Route path="/sites/:site" exact={true}>
            {redirect(<SiteDetailsPage {...pageProps} />)}
          </Route>
          <Route path="/history" exact={true}>
            {redirect(<HistoryPage {...pageProps} />)}
          </Route>
          <Route path="/">
            <ErrorPage />
          </Route>
        </Switch>
      </AppLayout>
    </BrowserRouter>
  );
};

export default BuckleApp;
