import React, {useContext} from 'react';
import Select, {SelectOption} from '@amzn/meridian/select';

import {useAppDispatch, useAppSelector} from '../../../app/hooks';
import {selectBusinessSelector, setBusiness3P, setBusinessUFF, setBusinessWFM} from '../selectBusinessSlice';
import {AuthContext, BusinessHierarchyNode} from '../../../app/AuthProvider';

// @ts-ignore
const BusinessSelector = (props) => {

  let authContext = useContext(AuthContext);
  if (!authContext || !authContext.permissionsTree) {
    authContext = props.authContext;
  }

  const businessSelection = useAppSelector(selectBusinessSelector);
  const dispatch = useAppDispatch();

  const handleChange = (business: string) => {
    switch (business) {
      case 'WFM':
        dispatch(setBusinessWFM());
        break;
      case 'UFF':
        dispatch(setBusinessUFF());
        break;
      case '3P':
        dispatch(setBusiness3P());
        break;
    }
  };

  return (
    <Select value={businessSelection} onChange={handleChange} width={300} label="Business" disabled={false} popoverMaxHeight={250}>
      {authContext.permissionsTree.map((node: BusinessHierarchyNode) => (
        <SelectOption label={node.value.toUpperCase()} value={node.value.toUpperCase()} />
      ))}
    </Select>
  );
};

export default BusinessSelector;
