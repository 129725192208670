import React from 'react';
import {css} from 'emotion';
import Row from '@amzn/meridian/row';
import DebouncedSearch from '../../../common/components/DebouncedSearch';

const rowSpacing = css`
  padding-bottom: 0.5%;
`;

interface IProps {
  searchInput: string;
  setSearchInput: React.Dispatch<React.SetStateAction<string>>;
}

const SiteListHeader = ({searchInput, setSearchInput}: IProps) => {
  return (
    <Row className={rowSpacing}>
      <DebouncedSearch placeholder='Search for sites...' searchInput={searchInput} setSearchInput={setSearchInput} />
    </Row>
  );
};

export default SiteListHeader;
