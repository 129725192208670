import React from 'react';
import {RouteComponentProps} from 'react-router-dom';
import Button from '@amzn/meridian/button';
import {IThemedCellTextProps} from '../../../common/components/ApiDataTable/ApiDataTableCells';
import {getTableStyles} from './SiteListTableStyles';

interface SiteDetailsProps extends IThemedCellTextProps {
  history: RouteComponentProps['history'];
}

export const SiteDetailsCell: React.FC<SiteDetailsProps> = ({dispatch, history, value, theme}) => {
  const {siteDetailsLinkCell} = getTableStyles(theme);
  return (
    <Button className={siteDetailsLinkCell} onClick={history.push} href={`/sites/${value}`} type="link">
      {value}
    </Button>
  );
};
