import {ApiGwClient, ApiGwErrorResponse} from '../apiGwClient';
import {GetConstraintsListResponse} from '../models/getConstraintsListResponse';
import handleError from './handleError';

export interface GetConstraintsListParams {
  businessType: string;
  region: string;
}

export const getConstraintsListEndpoint = async (
  backendApiGwClient: ApiGwClient,
  params: GetConstraintsListParams
): Promise<GetConstraintsListResponse | void> => {
  try {
    const {businessType, region} = params;
    return (
      await backendApiGwClient.invokeApi(
        {
          businessType,
          region,
        },
        '/business/{businessType}/region/{region}/constraints',
        'GET',
        {},
        null
      )
    ).data;
  } catch (error) {
    handleError(error as ApiGwErrorResponse);
  }
};
