import React from 'react';
import Column from '@amzn/meridian/column';
import Text from '@amzn/meridian/text';
import {css} from 'emotion';
import {IPageProps} from '../../common/pages/models';
import BucklePage from '../../common/pages/BucklePage';
import underConstruction from '../cached-landing-page/assets/under-construction.png';

const mastheadAndColumnSpacing = css`
  padding-top: 0.7%;
  padding-left: 1.5%;
  padding-right: 1.5%;
`;

const underConstructionStyle = css`
  padding-top: 5%;
  height: 17em;
`;

const textPadding = css`
  padding-bottom: 1.4%;
`;

const CachedLandingPage = () => {
  return (
    <Column alignmentHorizontal="center" className={mastheadAndColumnSpacing}>
      <Text type="h400" className={textPadding}>
        View to see all Capacity Constraints side-by-side
      </Text>
      <img src={underConstruction} className={underConstructionStyle} alt="Page under construction" />
    </Column>
  );
};

export default (props: IPageProps) => (
  <BucklePage title="Dashboard" {...props}>
    <CachedLandingPage />
  </BucklePage>
);
