import React, {useEffect, useState} from 'react';
import {css} from 'emotion';

import {useHistory, useParams} from 'react-router-dom';
import {useAppSelector} from '../../app/hooks';
import {selectCachedBusiness, selectCachedCountry, selectCachedRegion} from '../cached-state/cachedStateSlice';
import ConstraintDetailsTable, {weeksToDisplay} from './components/ConstraintDetailsTable';
import {GetConstraintResponse} from '../../common/apis/models/getConstraintResponse';
import {getConstraint} from '../../common/apis/BackendApisClient';
import {AuthPermissions, authTokenPayload, getAuthPermissions, Node} from '../../app/AuthProvider';
import ConstraintUpdatedByText from './components/ConstraintUpdatedByText';
import {getLocalizedDateTimeStrings} from '../../common/utils/dates';
import buildConstraintMetadata from './utils/buildConstraintMetadata';
import BucklePage from '../../common/pages/BucklePage';
import {IPageProps} from '../../common/pages/models';
import PageLoading from '../../common/components/PageLoading';

const mastheadAndColumnSpacing = css`
  padding-top: 0.5%;
  padding-left: 1.5%;
  padding-right: 1.5%;
`;

const defaultAuthPermissions: AuthPermissions = {
  canViewConstraintsSummary: false,
  canViewSitesList: false,
  canViewSitesDetail: false,
  canViewConstraintsList: false,
  canViewConstraintsDetail: false,
  canViewConstraintsHistory: false,
  canEditConstraints: false,
};

interface IUrlParams {
  constraint: string;
}

const parseConstraintUrlParam = (constraintUrlParam: string) => {
  const paramSections = constraintUrlParam ? constraintUrlParam.split('-') : [];
  return {
    constraintName: paramSections[0] || '',
    constraintId:
      paramSections.length === 2 && paramSections[1] !== '' && !Number.isNaN(Number(paramSections[1]))
        ? Number(paramSections[1])
        : -1,
  };
};

const ConstraintDetailsPage = (props: IPageProps) => {
  const history = useHistory();
  const {setPageTitle, setEntityMetadata} = props;
  const {constraint} = useParams<IUrlParams>();
  const businessSelection = useAppSelector(selectCachedBusiness);
  const regionSelection = useAppSelector(selectCachedRegion);
  const countrySelection = useAppSelector(selectCachedCountry);
  const [user, setUser] = useState('');
  const [constraintDetails, setGetConstraintDetails] = useState<GetConstraintResponse | void>();
  const [authPermissions, setAuthPermissions] = useState(defaultAuthPermissions);

  const {constraintName, constraintId} = parseConstraintUrlParam(constraint);

  useEffect(() => {
    setPageTitle(constraintName);
  }, [setPageTitle, constraintName]);

  useEffect(() => {
    const getUser = async () => {
      const tokenPayload = await authTokenPayload();
      setUser(tokenPayload?.userName);
    };
    getUser();

    const getAuthPermissionsAsync = async () => {
      if (businessSelection && regionSelection) {
        let hierarchy: Node[] = [
          {name: 'businessType', value: businessSelection},
          {name: 'regionId', value: regionSelection},
        ];
        countrySelection && countrySelection !== 'undefined' && hierarchy.push({name: 'country', value: countrySelection});

        const permissions = await getAuthPermissions(hierarchy);
        setAuthPermissions(permissions);
      }
    };
    getAuthPermissionsAsync();

    let currDate = new Date();
    getConstraint({
      businessType: businessSelection!,
      region: regionSelection!,
      country: countrySelection!,
      constraintId: constraintId,
      startTime: new Date(currDate.setDate(currDate.getDate() - currDate.getDay())).toLocaleDateString(),
    }).then((response) => {
      if (!response) {
        // constraint not found, so go to 404
        history.push('/404');
      }

      function addCommasToNumber(x: string) {
        return x?.replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ',');
      }
      response!.valuesData.forEach((row) => {
        for (let i = 0; i < weeksToDisplay; i++) {
          row[`column${i}`] = parseFloat(row[`column${i}`]?.replace(/,/g, '')).toFixed(2); // force numbers to have 2 decimal places e.g. 200 => 200.00
          row[`column${i}`] = addCommasToNumber(row[`column${i}`]); // add commas in appropriate locations e.g. 200000 => 200,000.00
        }
      });
      setGetConstraintDetails(response);
      if (response) {
        // Set the page title again in case it's different than the parsed value
        setPageTitle(response.constraintName);
        setEntityMetadata(buildConstraintMetadata(response));
      }
    });
  }, [
    businessSelection,
    regionSelection,
    countrySelection,
    constraintId,
    history,
    setGetConstraintDetails,
    setPageTitle,
    setEntityMetadata,
  ]);

  if (constraintDetails) {
    const updatedTime = new Date(constraintDetails.updatedAt.toString());
    const formattedUpdatedTime = getLocalizedDateTimeStrings(updatedTime);
    const enableEditing = authPermissions!.canEditConstraints === true || constraintDetails.owners!.includes(user);
    return (
      <div className={mastheadAndColumnSpacing}>
        <ConstraintUpdatedByText
          updatedBy={constraintDetails.updatedBy.toString()}
          updatedDateString={formattedUpdatedTime.date}
          updatedTimeString={formattedUpdatedTime.time}
        />
        <ConstraintDetailsTable
          history={history}
          constraintDetails={constraintDetails}
          enableEdits={enableEditing}
          constraintId={constraintId.toString()}
          businessType={businessSelection!}
          region={regionSelection!}
          user={user}
          country={countrySelection ?? undefined}
        />
      </div>
    );
  } else {
    return <PageLoading />;
  }
};

export default (props: IPageProps) => (
  <BucklePage {...props}>
    <ConstraintDetailsPage {...props} />
  </BucklePage>
);
