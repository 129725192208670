import React, {useCallback, useEffect, useState} from 'react';
import {RouteComponentProps} from 'react-router-dom';
import {ITableProps, kaReducer, Table} from 'ka-table';
import {ICellTextProps} from 'ka-table/props';
import {hideLoading, loadData, search, showLoading, updateData} from 'ka-table/actionCreators';
import {DataType, SortingMode, PagingPosition, SortDirection, ActionType} from 'ka-table/enums';
import {DispatchFunc} from 'ka-table/types';
import 'ka-table/style.scss';
import {Column} from 'ka-table/models';

import {getConstraintsList} from '../../../common/apis/BackendApisClient';
import {GetConstraintsListResponse} from '../../../common/apis/models/getConstraintsListResponse';
import {getTableStyles} from './ConstraintsListTableStyles';
import {useAppSelector} from '../../../app/hooks';
import {ITheme} from '../../../common/styles/themes/models';
import {selectTheme} from '../../themes/themeStateSlice';
import {ConstraintsDetailsCell} from './ConstraintsListCells';
import ConstraintsListHeader from './ConstraintsListHeader';
import formatConstraintsList from '../utils/formatConstraintsList';
import {DateCell} from '../../../common/components/ApiDataTable/ApiDataTableCells';
import PageLoading from '../../../common/components/PageLoading';

const DEFAULT_PAGE_SIZE = 10;
const ALLOWED_PAGE_SIZES = [10, 20, 50];

const getColumnTypeMapping = (
  history: RouteComponentProps['history'],
  theme: ITheme,
  props: React.PropsWithChildren<ICellTextProps>
) => {
  switch (props.column.key) {
    case 'constraint':
      return <ConstraintsDetailsCell history={history} theme={theme} {...props} />;
    case 'updatedAt':
      return <DateCell theme={theme} multiLine={false} {...props} />;
  }
};

const buildTable = (constraintsList: GetConstraintsListResponse | void): ITableProps => {
  const columns: Column[] = [
    {key: 'constraint', title: 'Constraint', dataType: DataType.Object, width: 55, sortDirection: SortDirection.Ascend},
    {key: 'inputGranularity', title: 'Input Granularity', dataType: DataType.String, width: 40},
    {key: 'inputValueType', title: 'Metric Type', dataType: DataType.String, width: 30},
    {key: 'inputType', title: 'Input Type', dataType: DataType.String, width: 30},
    {key: 'updatedAt', title: 'Last Updated', dataType: DataType.String, width: 40},
    {key: 'updatedBy', title: 'Updated By', dataType: DataType.String, width: 30},
    {key: 'usageTags', title: 'Usage Tags', dataType: DataType.String, width: 40},
    {key: 'shouldBlowout', title: 'Blowout', dataType: DataType.String, width: 25},
  ];

  const tablePropsInit: ITableProps = {
    columns,
    data: (constraintsList && constraintsList.records) || [],
    rowKeyField: 'constraintId',
    sortingMode: SortingMode.Single,
    singleAction: loadData(),
    loading: {enabled: true},
    paging: {
      enabled: true,
      pageIndex: 0,
      pageSize: DEFAULT_PAGE_SIZE,
      pageSizes: ALLOWED_PAGE_SIZES,
      position: PagingPosition.Bottom,
    },
    search: ({searchText, rowData}) => {
      return rowData['constraint']?.constraintName.toLowerCase().includes(searchText.toLowerCase());
    },
    sort: ({column}) => {
      if (column.key === 'constraint') {
        return (a, b) => {
          if (!a || !b) return 0;
          return a.constraintName === b.constraintName
            ? 0
            : a.constraintName < b.constraintName
            ? column.sortDirection === SortDirection.Ascend
              ? -1
              : 1
            : column.sortDirection === SortDirection.Ascend
            ? 1
            : -1;
        };
      }
    },
  };

  return tablePropsInit;
};

interface IProps {
  history: RouteComponentProps['history'];
  constraintsList: GetConstraintsListResponse | void;
  setConstraintsList: React.Dispatch<React.SetStateAction<void | GetConstraintsListResponse | undefined>>;
  businessType: string;
  region: string;
}

const ConstraintsListTable = ({history, constraintsList, setConstraintsList, businessType, region}: IProps) => {
  const theme = useAppSelector(selectTheme);

  const [tableProps, changeTableProps] = useState(buildTable(constraintsList));
  const [searchInput, setSearchInput] = useState('');

  const dispatch: DispatchFunc = useCallback(
    (action) => {
      changeTableProps((prevState: ITableProps) => kaReducer(prevState, action));
      if (action.type === ActionType.LoadData) {
        dispatch(showLoading());
        dispatch(updateData(formatConstraintsList(constraintsList)?.records || []));
        dispatch(hideLoading());
      }
    },
    [constraintsList]
  );

  useEffect(() => {
    getConstraintsList({
      businessType,
      region,
    }).then((response) => {
      setConstraintsList(response);
      buildTable(response);
    });
  }, [businessType, region, setConstraintsList]);

  useEffect(() => {
    changeTableProps(buildTable(constraintsList));
  }, [constraintsList, changeTableProps]);

  // Perform client-side search
  useEffect(() => {
    dispatch(search(searchInput));
  }, [dispatch, searchInput]);

  const {tableStyle} = getTableStyles(theme);
  if (constraintsList) {
    return (
      <div className={tableStyle}>
        <ConstraintsListHeader searchInput={searchInput} setSearchInput={setSearchInput} />
        <Table
          {...tableProps}
          dispatch={dispatch}
          childComponents={{
            noDataRow: {
              content: () => 'No Data Found',
            },
            cellText: {
              content: getColumnTypeMapping.bind(getColumnTypeMapping, history, theme),
            },
            headCell: {
              elementAttributes: (props) => {
                if (props.column.key === 'constraint') {
                  return {
                    style: {
                      ...props.column.style,
                      position: 'sticky',
                      left: 0,
                      zIndex: 10,
                      textAlign: 'left',
                    },
                  };
                }
              },
            },
            cell: {
              elementAttributes: (props) => {
                if (props.column.key === 'constraint') {
                  return {
                    style: {
                      ...props.column.style,
                      position: 'sticky',
                      left: 0,
                      color: theme.StickyColumn,
                      backgroundColor: theme.StickyColumnBackground,
                      textAlign: 'left',
                      paddingRight: 0,
                    },
                  };
                }
              },
            },
          }}
        />
      </div>
    );
  } else {
    return <PageLoading />;
  }
};

export default ConstraintsListTable;
