import {format} from 'date-fns';

const DATE_FORMAT_STRING = 'yyyy-MM-dd';

export const getISODate = (value: Date): string => {
  return value.toISOString().substring(0, 10);
};

export const dateTodayYesterdayFormatter = (dateString: string): string => {
  const now = new Date();
  const nowDate = now.toLocaleDateString();
  const yesterdayDate = new Date(now.setDate(now.getDate() - 1)).toLocaleDateString();

  return dateString === nowDate ? 'Today' : dateString === yesterdayDate ? 'Yesterday' : dateString;
};

export const getLocalizedDateTimeStrings = (value: Date): {date: string; time: string} => {
  return {
    date: format(value, DATE_FORMAT_STRING),
    time: value.toLocaleTimeString(),
  };
};
