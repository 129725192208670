import {createSlice, PayloadAction} from '@reduxjs/toolkit';
import {RootState} from '../../app/store';

export interface ISelectBusinessState {
  business: string | undefined;
  region: {
    regionSelection: string | undefined;
    regionDisabled: boolean;
  };
  country: {
    countrySelection: string | undefined;
    countryVisible: boolean;
  };
  canEnterPortal: boolean;
}

const initialState: ISelectBusinessState = {
  business: undefined,
  region: {
    regionSelection: undefined,
    regionDisabled: true,
  },
  country: {
    countrySelection: undefined,
    countryVisible: false,
  },
  canEnterPortal: false,
};

const selectBusinessSlice = createSlice({
  name: 'selectBusiness',
  initialState,
  reducers: {
    setBusinessWFM: (state) => {
      state.business = 'WFM';
      state.region = {
        regionSelection: 'NA',
        regionDisabled: false,
      };
      state.country = {
        countrySelection: undefined,
        countryVisible: false,
      };
      state.canEnterPortal = true;
    },
    setBusinessUFF: (state) => {
      state.business = 'UFF';
      state.region = {
        regionSelection: 'NA',
        regionDisabled: false,
      };
      state.country = {
        countrySelection: undefined,
        countryVisible: false,
      };
      state.canEnterPortal = true;
    },
    setRegionUFFtoNA: (state) => {
      state.region = {
        regionSelection: 'NA',
        regionDisabled: false,
      };
      state.country = {
        countrySelection: undefined,
        countryVisible: false,
      };
      state.canEnterPortal = true;
    },
    setRegionUFFtoEU: (state) => {
      state.region = {
        regionSelection: 'EU',
        regionDisabled: false,
      };
      state.country = {
        countrySelection: undefined,
        countryVisible: false,
      };
      state.canEnterPortal = true;
    },
    setRegionUFFtoFE: (state) => {
      state.region = {
        regionSelection: 'FE',
        regionDisabled: false,
      };
      state.country = {
        countrySelection: undefined,
        countryVisible: false,
      };
      state.canEnterPortal = false;
    },
    setBusiness3P: (state) => {
      state.business = '3P';
      state.region = {
        regionSelection: 'NA',
        regionDisabled: false,
      };
      state.country = {
        countrySelection: undefined,
        countryVisible: false,
      };
      state.canEnterPortal = true;
    },
    setRegion3PtoNA: (state) => {
      state.region = {
        regionSelection: 'NA',
        regionDisabled: false,
      };
      state.country = {
        countrySelection: undefined,
        countryVisible: false,
      };
      state.canEnterPortal = true;
    },
    setRegion3PtoEU: (state) => {
      state.region = {
        regionSelection: 'EU',
        regionDisabled: false,
      };
      state.country = {
        countrySelection: undefined,
        countryVisible: true,
      };
      state.canEnterPortal = false;
    },
    setCountry: (state, action: PayloadAction<string>) => {
      state.country.countrySelection = action.payload;
      state.canEnterPortal = true;
    },
  },
});

export const {
  setBusinessWFM,
  setBusinessUFF,
  setRegionUFFtoNA,
  setRegionUFFtoEU,
  setRegionUFFtoFE,
  setBusiness3P,
  setRegion3PtoNA,
  setRegion3PtoEU,
  setCountry,
} = selectBusinessSlice.actions;

export const selectBusinessSelector = (state: RootState) => state.selectBusiness.business;
export const selectRegionSelector = (state: RootState) => state.selectBusiness.region;
export const selectCountrySelector = (state: RootState) => state.selectBusiness.country;
export const selectCanEnterPortalSelector = (state: RootState) => state.selectBusiness.canEnterPortal;

export default selectBusinessSlice.reducer;
