import React, {useContext} from 'react';
import Select, {SelectOption} from '@amzn/meridian/select';

import {useAppDispatch, useAppSelector} from '../../../app/hooks';
import {
  selectBusinessSelector,
  selectRegionSelector,
  setRegion3PtoEU,
  setRegion3PtoNA,
  setRegionUFFtoEU,
  setRegionUFFtoFE,
  setRegionUFFtoNA,
} from '../selectBusinessSlice';
import {AuthContext, BusinessHierarchyNode} from '../../../app/AuthProvider';

// @ts-ignore
const RegionSelector = (props) => {

  let authContext = useContext(AuthContext);
  if (!authContext || !authContext.permissionsTree) {
    authContext = props.authContext;
  }

  const businessSelection = useAppSelector(selectBusinessSelector);

  const {regionSelection, regionDisabled} = useAppSelector(selectRegionSelector);
  const dispatch = useAppDispatch();

  const handleChange = (region: string) => {
    switch (businessSelection) {
      case 'UFF':
        switch (region) {
          case 'NA':
            dispatch(setRegionUFFtoNA());
            break;
          case 'EU':
            dispatch(setRegionUFFtoEU());
            break;
          case 'FE':
            dispatch(setRegionUFFtoFE());
            break;
        }
        break;
      case '3P':
        switch (region) {
          case 'NA':
            dispatch(setRegion3PtoNA());
            break;
          case 'EU':
            dispatch(setRegion3PtoEU());
            break;
        }
        break;
    }
  };

  const regionOptions = authContext.permissionsTree
      .find((business: BusinessHierarchyNode) => business.value.toUpperCase() === businessSelection);

  return (
    <Select
      value={regionSelection}
      onChange={handleChange}
      width={300}
      label="Region"
      disabled={regionDisabled}
      popoverMaxHeight={200}
    >
      {regionOptions?.nodes.map((node: BusinessHierarchyNode) => (
        <SelectOption label={node.value.toUpperCase()} value={node.value.toUpperCase()} />
      ))}
    </Select>
  );
};

export default RegionSelector;
