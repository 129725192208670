import omit from 'lodash.omit';
import {GetSiteResponse, SiteConstraintValues} from '../../../common/apis/models/getSiteResponse';
import {GetForecastResponse} from '../../../common/apis/models/getForecastResponse';

interface NumberMap {
  [key: string]: number | null;
}
interface StringMap {
  [key: string]: string;
}
interface StringNumberMap {
  [key: string]: string | number | null;
}

export interface DateIdMap extends StringMap {}
export interface SummaryDetailRecord extends StringNumberMap {}
export interface SummaryRecord {
  [key: string]: string | number | null | StringNumberMap[];
  summaryDetailRows: SummaryDetailRecord[];
}

export interface ConstraintValueRecord {
  [key: string]: string | number | object | null;
  constraintId: string;
  constraintName: string;
  constraintValueType: string;
  constraintUsageTags: string[];
}

interface ConstraintAnalysis {
  constraintsAffectingOperationsData: ConstraintValueRecord[];
  otherConstraintsData: ConstraintValueRecord[];
  minimumConstraints: DateIdMap;
  unconstrainedForecast: StringNumberMap[];
  operationalCapacity: NumberMap;
  operationalGap: NumberMap;
  summaryRecords: SummaryRecord[];
}

const CONSTRAINT_TYPE_UNITS = 'UNITS';
const CONSTRAINT_USAGE_LABOR = 'SNOP';

const affectsOperationalCapacity = (constraintValue: ConstraintValueRecord): boolean =>
  constraintValue.constraintValueType === CONSTRAINT_TYPE_UNITS &&
  constraintValue.constraintUsageTags.indexOf(CONSTRAINT_USAGE_LABOR) >= 0;

const getConstraintDates = (constraints: SiteConstraintValues[]): string[] => {
  const uniqueConstraintDates = new Set<string>();
  constraints.forEach((constraintValues) => {
    Object.keys(omit(constraintValues, ['constraintId', 'constraintName', 'constraintValueType', 'constraintUsageTags'])).forEach(
      (date) => uniqueConstraintDates.add(date)
    );
  });
  return Array.from(uniqueConstraintDates);
};

const getUsageTags = (constraintValues: SiteConstraintValues): string[] =>
  constraintValues.constraintUsageTags
    .split(',')
    .filter(Boolean)
    .map((tag) => tag.trim());

const normalizeValues = (constraintValues: SiteConstraintValues, constraintDates: string[]) => {
  const dateValues: {[date: string]: number | null} = {};
  constraintDates.forEach((constraintDate) => {
    dateValues[constraintDate] =
      constraintValues[constraintDate] === undefined ? null : Number(constraintValues[constraintDate].replace(',', ''));
  });
  return dateValues;
};

const normalizeStringValues = (constraintValues: SiteConstraintValues, constraintDates: string[]) => {
  const dateValues: {[date: string]: string | null} = {};
  constraintDates.forEach((constraintDate) => {
    dateValues[constraintDate] = constraintValues[constraintDate];
  });
  return dateValues;
};

const calculateMinimums = (constraintValues: ConstraintValueRecord[], constraintDates: string[]) => {
  const minimumIds: {[date: string]: string} = {};
  constraintDates.forEach((date: string) => {
    let minVal = Number.MAX_SAFE_INTEGER;
    let minId = '';
    constraintValues.forEach((constraintValue: ConstraintValueRecord) => {
      if (typeof constraintValue[date] !== 'number') {
        return;
      } // do not check empty values
      const numVal = Number(constraintValue[date]);
      if (!minId || numVal < minVal) {
        minVal = numVal;
        minId = constraintValue.constraintId;
      }
    });
    if (minId) {
      minimumIds[date] = minId;
    }
  });
  return minimumIds;
};

const forecastStringFields = new Set(['siteId', 'forecastType']);
const normalizeUnconstrainedForecast = (forecastRecords: StringMap[]): StringNumberMap[] => {
  return forecastRecords.map((forecast) => {
    const normalized: StringNumberMap = {};
    Object.keys(forecast).forEach((key) => {
      if (forecastStringFields.has(key)) {
        normalized[key] = forecast[key];
      } else {
        normalized[key] = forecast[key] ? Number(forecast[key].replace(',', '')) : null;
      }
    });
    return normalized;
  });
};

const aggregateForecast = (forecast: StringNumberMap[]): NumberMap => {
  const aggregated: NumberMap = {};
  forecast.forEach((forecast: StringNumberMap) => {
    Object.keys(forecast).forEach((key) => {
      if (forecastStringFields.has(key)) {
        return;
      }
      const val = typeof forecast[key] === 'number' ? Number(forecast[key]) : 0;
      aggregated[key] = (aggregated[key] || 0) + val;
    });
  });
  return aggregated;
};

const calculateOperationalCapacity = (
  constraintsAffectingOperationsData: ConstraintValueRecord[],
  forecast: NumberMap
): NumberMap => {
  return Object.keys(forecast).reduce((acc: NumberMap, date: string) => {
    acc[date] = constraintsAffectingOperationsData.reduce((min: number, record: ConstraintValueRecord) => {
      return typeof record[date] === 'number' && Number(record[date]) < min ? Number(record[date]) : min;
    }, Number.MAX_SAFE_INTEGER);
    return acc;
  }, {});
};

const calculateOperationalGap = (operationalCapacity: NumberMap, forecast: NumberMap): NumberMap => {
  return Object.keys(forecast).reduce((acc: NumberMap, date: string) => {
    acc[date] = ((Number(forecast[date]) - Number(operationalCapacity[date])) / Math.max(1, Number(forecast[date]))) * -1;
    return acc;
  }, {});
};

const formatAsPercent = (numericMap: NumberMap): StringMap => {
  return Object.keys(numericMap).reduce((acc: StringMap, date: string) => {
    acc[date] = numericMap[date]
      ? `${(Number(numericMap[date]) * 100).toLocaleString(navigator.language, {maximumFractionDigits: 1})}%`
      : '';
    return acc;
  }, {});
};

const formatAsUINumber = (numericMap: NumberMap | StringNumberMap): StringMap => {
  return Object.keys(numericMap).reduce((acc: StringMap, date: string) => {
    acc[date] = numericMap[date]
      ? typeof numericMap[date] === 'number'
        ? Number(numericMap[date]).toLocaleString(navigator.language, {maximumFractionDigits: 2})
        : String(numericMap[date])
      : '';
    return acc;
  }, {});
};

const capitalize = (value: string | null | undefined): string =>
  value ? value.substring(0, 1).toUpperCase() + value.substring(1) : '';

const buildSummaryRecords = (
  unconstrainedForecast: StringNumberMap[],
  aggregatedForecast: NumberMap,
  operationalCapacity: NumberMap,
  operationalGap: NumberMap
): SummaryRecord[] => {
  const summaryRows: SummaryRecord[] = [];
  if (unconstrainedForecast.length) {
    summaryRows.push({
      ...formatAsUINumber(aggregatedForecast),
      summaryTitle: 'Unconstrained S&OP',
      summaryTitleTooltip: '',
      summaryDetailRows: unconstrainedForecast.map(
        (unconstrainedRecord) =>
          ({
            ...omit(formatAsUINumber(unconstrainedRecord), ['siteId', 'forecastType']),
            summaryTitle: `Unconstrained S&OP - ${capitalize(unconstrainedRecord.forecastType?.toString())}`,
          } as StringNumberMap)
      ),
    });
  }
  if (operationalCapacity && Object.keys(operationalCapacity).length) {
    summaryRows.push({
      ...formatAsUINumber(operationalCapacity),
      summaryTitle: 'Operational Capacity',
      summaryTitleTooltip: '',
      summaryDetailRows: [],
    });
  }
  if (operationalGap && Object.keys(operationalGap).length) {
    summaryRows.push({
      ...formatAsPercent(operationalGap),
      summaryTitle: 'Potential Operational Gap',
      summaryTitleTooltip:
        'Buckle publishes operational capacity at the slot level, and the actual constraining is performed by Excelsior',
      summaryDetailRows: [],
    });
  }
  return summaryRows;
};

const analyzeConstraints = (siteDetails: GetSiteResponse, forecast: GetForecastResponse | undefined | void): ConstraintAnalysis => {
  const unconstrainedValues = forecast?.forecastValues || [];
  const allConstraints = siteDetails?.constraintValues || [];

  // Parse data and place constraints into buckets
  const constraintDates = getConstraintDates(allConstraints);
  const constraintsAffectingOperationsData: ConstraintValueRecord[] = [];
  const constraintsAffectingOperationsTransformedData: ConstraintValueRecord[] = [];
  const otherConstraintsData: ConstraintValueRecord[] = [];
  allConstraints.forEach((constraintValues) => {
    const usageTags = getUsageTags(constraintValues);
    const transformed: ConstraintValueRecord = {
      ...constraintValues,
      constraintUsageTags: usageTags,
      ...normalizeValues(constraintValues, constraintDates),
    };
    const transformedFormatted: ConstraintValueRecord = {
      ...constraintValues,
      constraintUsageTags: usageTags,
      ...normalizeStringValues(constraintValues, constraintDates),
    };
    if (affectsOperationalCapacity(transformed)) {
      constraintsAffectingOperationsTransformedData.push(transformed);
      constraintsAffectingOperationsData.push(transformedFormatted);
    } else {
      otherConstraintsData.push(transformedFormatted);
    }
  });

  // Calculate minimum constraint for each date
  const minimumConstraints = calculateMinimums(constraintsAffectingOperationsTransformedData, constraintDates);
  const unconstrainedForecast = normalizeUnconstrainedForecast(unconstrainedValues);
  const aggregatedForecast = aggregateForecast(unconstrainedForecast);
  const operationalCapacity = calculateOperationalCapacity(constraintsAffectingOperationsTransformedData, aggregatedForecast);
  const operationalGap = calculateOperationalGap(operationalCapacity, aggregatedForecast);
  const summaryRecords = buildSummaryRecords(unconstrainedForecast, aggregatedForecast, operationalCapacity, operationalGap);

  return {
    constraintsAffectingOperationsData,
    otherConstraintsData,
    minimumConstraints,
    unconstrainedForecast,
    operationalCapacity,
    operationalGap,
    summaryRecords,
  };
};

export default analyzeConstraints;
